<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <raiser-statistics />
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import RaiserStatistics from '@/components/dashboard/RaiserStatistics.vue'

export default {
  name: 'SupervisorGraphRaisers',

  middleware: ['auth', 'supervisor'],

  components: {
    RaiserStatistics
  },

  metaInfo () {
    return {
      title: 'Raisers Graph'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
